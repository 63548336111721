import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ForgotpasswordService {
	constructor(private httpClient: HttpClient) { }
	
	forgotpasswordmail(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'forgotpassword/forgotpasswordmail', data);
	}
	
	changePassword(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'forgotpassword/changepassword', data);
	}
}