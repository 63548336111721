<form [formGroup]="currencyForm" (ngSubmit)="onSubmit()">
	<div class="card-body">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group">
					<label for="name">Currency Name <span class="requiredtext">*</span></label>
					<input type="text" class="form-control" id="name" placeholder="Enter Currency Name" formControlName="name">
					<div *ngIf="currencyFormControl.name.invalid && (currencyFormControl.name.dirty || currencyFormControl.name.touched)" class="requirederror">
						<div *ngIf="currencyFormControl.name.errors?.required">
							Currency Name field is required.
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<label for="symbol">Currency Symbol <span class="requiredtext">*</span></label>
					<input type="text" class="form-control" id="symbol" placeholder="Enter Currency Symbol...e.g kshs,USD etc" formControlName="symbol">
					<div *ngIf="currencyFormControl.symbol.invalid && (currencyFormControl.symbol.dirty || currencyFormControl.symbol.touched)" class="requirederror">
						<div *ngIf="currencyFormControl.symbol.errors?.required">
							Currency Symbol field is required.
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="card-footer">
		<input type="hidden" formControlName="actionid">
		<button type="submit" [disabled]="currencyForm.invalid" class="btn btn-primary">Submit</button>
	</div>
</form>