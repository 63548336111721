import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from '@angular/forms';
import { CommonServicescategoriesActionComponent } from './common-servicescategories-action.component';

@NgModule({
	declarations: [
		CommonServicescategoriesActionComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
	],
	exports: [
		CommonServicescategoriesActionComponent
	]
})

export class CommonServicescategoriesActionModule { }
