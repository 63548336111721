<form [formGroup]="servicescategoriesForm" (ngSubmit)="onSubmit()">
	<div class="card-body">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group">
					<label for="name">Name <span class="requiredtext">*</span></label>
					<input type="text" class="form-control" id="name" placeholder="Enter Service Name" formControlName="name">
					<div *ngIf="servicescategoriesFormControl.name.invalid && (servicescategoriesFormControl.name.dirty || servicescategoriesFormControl.name.touched)" class="requirederror">
						<div *ngIf="servicescategoriesFormControl.name.errors?.required">
							Name field is required.
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<label for="image">Image</label>
					<div class="input-group">
						<div class="custom-file">
							<input type="file" class="custom-file-input" id="image" (change)="onFileChange($event)"
								accept="image/*">
							<label class="custom-file-label" for="image">Choose file</label>
						</div>
						<a href="{{image}}" class="plr5" target="_blank" *ngIf="image!=''"><img src="{{image}}"
								width="100"></a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="card-footer">
		<input type="hidden" formControlName="actionid">
		<button type="submit" [disabled]="servicescategoriesForm.invalid" class="btn btn-primary">Save</button>
	</div>
</form>
