import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from '@angular/forms';
import { CommonCurrencyActionComponent } from './common-currency-action.component';

@NgModule({
	declarations: [
		CommonCurrencyActionComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
	],
	exports: [
		CommonCurrencyActionComponent
	]
})

export class CommonCurrencyActionModule { }
