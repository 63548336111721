import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { ServicescategoriesService } from '../../../../service/servicescategories.service';
import { environment } from '../../../../../environments/environment';

@Component({
	selector: 'app-common-servicescategories-action',
	templateUrl: './common-servicescategories-action.component.html'
})

export class CommonServicescategoriesActionComponent implements OnInit {
	@Input() type: any;
	@Input() actionid: any;

	@Output() nextpage = new EventEmitter();

	servicescategoriesForm: FormGroup;
	
	image:any = '';
	
	constructor(
		public title: Title,
		public router: Router,
		private activatedroute: ActivatedRoute,
		public fb: FormBuilder,
		private toastrService: ToastrService,
		public servicescategoriesService: ServicescategoriesService
	) {}
	
	ngOnInit() {
		this.title.setTitle(environment.pagetitle['23']);
		if(this.type==1) this.actionid = this.activatedroute.snapshot.paramMap.get('id')!=null ? this.activatedroute.snapshot.paramMap.get('id') : '';
		
		this.servicescategoriesForm = this.fb.group({
			name: ['', Validators.required],
			image: [''],
			actionid: ['']
		})
		
		if(this.actionid!=''){
			this.servicescategoriesService.getData({id : this.actionid}).subscribe(
				result => {
					this.servicescategoriesForm.setValue({
						name: result.success.name,
						image: result.success.image,
						actionid: result.success.id
					});

					this.image = environment.s3Bucket+result.success.image;
				},
				error => {
					this.toastrService.error('Services Categories not found.', 'Error', {timeOut: 3000});
					this.router.navigate(['servicescategories']);
				}
			);
		}
	}
	
	onSubmit() {
		if (this.servicescategoriesForm.valid) {
			this.servicescategoriesService.action(this.servicescategoriesForm.value).subscribe(
				result => {
					this.toastrService.success('Services Categories submitted successfully.', 'Success', {timeOut: 3000});
					this.servicescategoriesForm.reset(); 
					if(this.type==1) this.router.navigate(['servicescategories']);
					if(this.type==2) this.nextpage.emit(1);
				},
				error => {
					this.toastrService.error('Try Later.', 'Error', {timeOut: 3000});
				}
			);
		}
	}

	onFileChange(event) {
		const reader = new FileReader();
    
		if(event.target.files && event.target.files.length) {
			const [file] = event.target.files;
			
			var filesplit = file.name.split('.');		
			if(!environment.imagevalidation.includes(filesplit[filesplit.length-1])){
				alert('Invalid Filetype');
				return false;
			}
			
			reader.readAsDataURL(file);

			reader.onload = () => {
				this.image =  reader.result;
				this.servicescategoriesForm.patchValue({image: reader.result});
			};
		}
	}
		
	get servicescategoriesFormControl() {
		return this.servicescategoriesForm.controls;
	}
}
