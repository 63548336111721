import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from '@angular/forms';
import { CommonPaymentmethodsActionComponent } from './common-paymentmethods-action.component';

@NgModule({
	declarations: [
		CommonPaymentmethodsActionComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
	],
	exports: [
		CommonPaymentmethodsActionComponent
	]
})

export class CommonPaymentmethodsActionModule { }
