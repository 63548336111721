import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { CurrencyService } from '../../../../service/currency.service';
import { environment } from '../../../../../environments/environment';
	
@Component({
	selector: 'app-common-currency-action',
	templateUrl: './common-currency-action.component.html'
})

export class CommonCurrencyActionComponent implements OnInit {
	@Input() type: any;
	@Input() actionid:any;

	@Output() nextpage = new EventEmitter();

	currencyForm: FormGroup;
	
	constructor(
		public title: Title,
		public router: Router,
		private activatedroute: ActivatedRoute,
		public fb: FormBuilder,
		private toastrService: ToastrService,
		public currencyService: CurrencyService
	) {}
	
	ngOnInit() {
		this.title.setTitle(environment.pagetitle['26']);
		if(this.type==1) this.actionid = this.activatedroute.snapshot.paramMap.get('id')!=null ? this.activatedroute.snapshot.paramMap.get('id') : '';
		
		this.currencyForm = this.fb.group({
			name: ['', Validators.required],
			symbol: ['', Validators.required],
			actionid: ['']
		})
		
		if(this.actionid!='' && this.actionid!=undefined){
			this.currencyService.getData({id : this.actionid}).subscribe(
				result => {
					this.currencyForm.setValue({
						name: result.success.name,
						symbol: result.success.symbol,
						actionid: result.success.id
					});
				},
				error => {
					this.toastrService.error('Currency not found.', 'Error', {timeOut: 3000});
					if(this.type==1) this.router.navigate(['currency']);
				}
			);
		}
	}
	
	onSubmit() {
		if (this.currencyForm.valid) {
			this.currencyService.action(this.currencyForm.value).subscribe(
				result => {
					this.toastrService.success('Currency submitted successfully.', 'Success', {timeOut: 3000});
					this.currencyForm.reset(); 
					if(this.type==1) this.router.navigate(['currency']);
					if(this.type==2) this.nextpage.emit(13);
				},
				error => {
					this.toastrService.error('Try Later.', 'Error', {timeOut: 3000});
				}
			);
		}
	}
		
	get currencyFormControl() {
		return this.currencyForm.controls;
	}
}
