import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class RoomsService {
	constructor(private httpClient: HttpClient) { }

	lists(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'rooms', data);
	}
	
	getData(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'rooms/getData', data);
	}
	
	action(data): Observable<any> {
		if(data.actionid==''){
			return this.httpClient.post(environment.apiurl+'rooms/create', data);
		}else{
			return this.httpClient.post(environment.apiurl+'rooms/edit', data);
		}
	}
	
	delete(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'rooms/delete', data);
	}
}