import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PettycashService {
	constructor(private httpClient: HttpClient) { }

	lists(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'pettycash', data);
	}		

	getData(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'pettycash/getData', data);
	}

	action(data): Observable<any> {
		if(data.actionid=='' || data.actionid==null){
			return this.httpClient.post(environment.apiurl+'pettycash/create', data);
		}else{
			return this.httpClient.post(environment.apiurl+'pettycash/edit', data);
		}
	}

}
