import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { PaymentmethodsService } from '../../../../service/paymentmethods.service';
import { environment } from '../../../../../environments/environment';

@Component({
	selector: 'app-common-paymentmethods-action',
	templateUrl: './common-paymentmethods-action.component.html'
})

export class CommonPaymentmethodsActionComponent implements OnInit {
	@Input() type: any;
	@Input() actionid: any;

	@Output() nextpage = new EventEmitter();

	paymentmethodsForm: FormGroup;
	
	constructor(
		public title: Title,
		public router: Router,
		private activatedroute: ActivatedRoute,
		public fb: FormBuilder,
		private toastrService: ToastrService,
		public paymentmethodsService: PaymentmethodsService
	) {}
	
	ngOnInit() {
		this.title.setTitle(environment.pagetitle['27']);
		if(this.type==1) this.actionid = this.activatedroute.snapshot.paramMap.get('id')!=null ? this.activatedroute.snapshot.paramMap.get('id') : '';
		
		this.paymentmethodsForm = this.fb.group({
			name: ['', Validators.required],
			details: [''],
			actionid: ['']
		})
		
		if(this.actionid!=''){
			this.paymentmethodsService.getData({id : this.actionid}).subscribe(
				result => {
					this.paymentmethodsForm.setValue({
						name: result.success.name,
						details: result.success.details,
						actionid: result.success.id
					});
				},
				error => {
					this.toastrService.error('Payment Method not found.', 'Error', {timeOut: 3000});
					this.router.navigate(['paymentmethods']);
				}
			);
		}
	}
	
	onSubmit() {
		if (this.paymentmethodsForm.valid) {
			this.paymentmethodsService.action(this.paymentmethodsForm.value).subscribe(
				result => {
					this.toastrService.success('Payment Method submitted successfully.', 'Success', {timeOut: 3000});
					this.paymentmethodsForm.reset(); 
					if(this.type==1) this.router.navigate(['paymentmethods']);
					if(this.type==2) this.nextpage.emit(5);
				},
				error => {
					this.toastrService.error('Try Later.', 'Error', {timeOut: 3000});
				}
			);
		}
	}
		
	get paymentmethodsFormControl() {
		return this.paymentmethodsForm.controls;
	}
}
