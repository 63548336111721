
<form [formGroup]="paymentmethodsForm" (ngSubmit)="onSubmit()">
	<div class="card-body">
		<div class="row">
			<div class="col-md-12">
				<div class="form-group">
					<label for="name">Payment Methods <span class="requiredtext">*</span></label>
					<input type="text" class="form-control" id="name" placeholder="Enter Payment Methods e.g Cash,Bank" formControlName="name">
					<div *ngIf="paymentmethodsFormControl.name.invalid && (paymentmethodsFormControl.name.dirty || paymentmethodsFormControl.name.touched)" class="requirederror">
						<div *ngIf="paymentmethodsFormControl.name.errors?.required">
							Payment Methods field is required.
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-12">
				<div class="form-group">
					<label for="description">Payment Method Details</label>
					<textarea class="form-control" id="details" placeholder="Cash Details Or For Bank ,Type Bank Name and Branch,Account No.And Code..." formControlName="details"></textarea>
				</div>
			</div>
		</div>
	</div>
	<div class="card-footer">
		<input type="hidden" formControlName="actionid">
		<button type="submit" [disabled]="paymentmethodsForm.invalid" class="btn btn-primary">Save</button>
	</div>
</form>