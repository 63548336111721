import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from '@angular/forms';
import { CommonProductscategoriesActionComponent } from './common-productscategories-action.component';

@NgModule({
	declarations: [
		CommonProductscategoriesActionComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
	],
	exports: [
		CommonProductscategoriesActionComponent
	]
})

export class CommonProductscategoriesActionModule { }
