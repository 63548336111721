import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KitsService {

  	constructor(private httpClient: HttpClient) {}

  	countriesList(data): Observable<any> {
		return this.httpClient.get(environment.apiurl+'auth/getCountries', data);
	}

	action(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'test-kits/action', data);
	}

	assignKit(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'assign-kit', data);
	}

	kitList(data):Observable<any> {
		return this.httpClient.get(environment.apiurl+'getKits', data);
	}
	
	getKitDetails(data):Observable<any> {
		return this.httpClient.post(environment.apiurl+'getKitDetails', data);
	}

	checkKitSerial(data):Observable<any> {
		return this.httpClient.post(environment.apiurl+'auth/check-valid-kit-serials', data);
	}

	registerKit(data,data1):Observable<any> {
		return this.httpClient.post(environment.apiurl+'auth/register-kit', data);
	}

	login(data):Observable<any> {
		return this.httpClient.post(environment.apiurl+'auth/kit-login', data);
	}

	templateList(data): Observable<any> {
		return this.httpClient.get(environment.apiurl+'getTemplates');
	}

	kitSettingAction(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'kit-settings/action', data);
	}

	kitSettingList(data): Observable<any> {
		return this.httpClient.get(environment.apiurl+'kit-settings/list', data);
	}

	kitSettingEdit(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'kit-settings/edit', data);
	}

	getTestKitListData(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'test-kits/getTestKitListData', data);
	}

	getDataById(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'test-kits/getDataById', data);
	}

	delete(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'test-kits/delete', data);
	}

	generateCertificate(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'test-kits/generateCertificate', data);
	}

	generateCSVFile(data): Observable<any> {
		return this.httpClient.post(environment.apiurl+'test-kits/generate-csv-file', data, { responseType: 'blob' });
	}

	generateRandoxReport(): Observable<any> {
		return this.httpClient.get(environment.apiurl+'test-kits/generate-randox-report', { responseType: 'blob' });
	}

	generateLatestRandoxReport(): Observable<any> {
		return this.httpClient.get(environment.apiurl+'test-kits/generate-latest-randox-report', { responseType: 'blob' });
	}

	generatePhePcrReport(): Observable<any> {
		return this.httpClient.get(environment.apiurl+'test-kits/generate-phe-pcr-report', { responseType: 'blob' });
	}

	generateLatestPhePcrReport(): Observable<any> {
		return this.httpClient.get(environment.apiurl+'test-kits/generate-latest-phe-pcr-report', { responseType: 'blob' });
	}

	generatePheAntigenReport(): Observable<any> {
		return this.httpClient.get(environment.apiurl+'test-kits/generate-phe-antigen-report', { responseType: 'blob' });
	}
	
	
}
